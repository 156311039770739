<template
  ><div class="ma-10">
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="3">
        <h3 class="text-h8 mt-3">
          <v-icon class="mr-2 mb-1">mdi-text</v-icon>Titel
        </h3>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="9">
        <v-select outlined dense></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="3">
        <h3 class="text-h8 my-3">
          <v-icon class="mr-2 mb-1">mdi-text-long</v-icon>Beschreibung
        </h3>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="9">
        <quill-editor v-model="task.description" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="3">
        <h3 class="text-h8 my-3">
          <v-icon class="mr-2 mb-1">mdi-calendar-blank</v-icon>Fälligkeitsdatum
        </h3>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="9">
        <v-btn color="primary" elevation="0" @click="openDatePicker"
          >Datum auswählen</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="3">
        <h3 class="text-h8 my-3">
          <v-icon class="mr-2 mb-1">mdi-bell-ring</v-icon>Benachrichtigungen
        </h3>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="9">
        <v-checkbox class="mt-3"></v-checkbox>
      </v-col>
    </v-row>

    <v-divider class="my-3"></v-divider>

    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="3">
        <h3 class="text-h8 my-3">
          <v-icon class="mr-2 mb-1">mdi-account-supervisor-circle</v-icon>Basis
        </h3>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="9">
        <v-btn color="primary" elevation="0" @click="openTaskObjects"
          >Auswählen</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="3">
        <h3 class="text-h8 my-3">
          <v-icon class="mr-2 mb-1">mdi-account-tie</v-icon>Zuordnung
        </h3>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="9">
        <v-btn color="primary" elevation="0" @click="openTaskAssigned"
          >Auswählen</v-btn
        >
      </v-col>
    </v-row>
    <v-footer absolute color="white">
      <v-btn color="primary" elevation="0" @click="create"
        ><v-icon class="mr-1">mdi-list-box</v-icon>Aufgabe erstellen</v-btn
      >
    </v-footer>
    <TaskAssigned ref="assigned" @save="savedAssigned" />
    <DatePicker ref="datePicker" @datePicked="datePicked" />
    <TaskObjects ref="objects"></TaskObjects></div
></template>

<script>
import TaskAssigned from '../../components/task/TaskAssigned.vue'
import { TaskClass } from '@/models/task/Task.js'
import DatePicker from '../../components/task/DatePicker.vue'
import dateTimeService from '@/services/cal/dateTimeService.js'
import TaskObjects from '../../components/task/TaskObjects.vue'

export default {
  data: () => ({
    task: new TaskClass(),
    dateTimeService
  }),
  components: {
    TaskAssigned,
    DatePicker,
    TaskObjects
  },
  methods: {
    openTaskAssigned() {
      this.$refs.assigned.open(
        this.task.assignedUsers,
        this.task.assignedWorkGroups
      )
    },
    openTaskObjects() {
      this.$refs.objects.open(this.task.objects)
    },
    savedAssigned(users, groups) {
      this.task.assignedUsers = users
      this.task.assignedWorkGroups = groups
    },
    openDatePicker() {
      this.$refs.datePicker.open()
    },
    datePicked(date) {
      this.task.dueDate = date
    },
    create() {
      //TODO: axios call
      console.warn(this.task)
      this.$router.push('/task')
      this.$toast.success('Aufgabe wurde erstellt')
    }
  }
}
</script>

<style></style>
